import { ReactComponent as IconCustomer } from '@whitelabel/media/icons/global/icon-16-customer.svg';
import { ReactComponent as IconHelp } from '@whitelabel/media/icons/global/icon-16-information.svg';
import { ReactComponent as IconChevronDown } from '@whitelabel/media/icons/small/chevron-down.svg';
import { isXCoverNextJSLive } from '@whitelabel/xcover-shared/helpers/utils';

export const accountDropdownLink = {
  key: 'accountDropdown',
  messageKey: 'accountLink',
  path: '/account',
  name: 'account',
};

export const claimsDropdownLink = {
  key: 'claimsDropdown',
  messageKey: 'claimsGlobalNavDropdownLink',
  path: '/claims/',
  name: 'claim',
  tag: 'a' as React.ElementType,
};

export const profileDropdownLink = {
  key: 'profileDropdown',
  messageKey: 'profileGlobalNavDropdownLink',
  path: '/profile',
  name: 'profile',
};

export const yourOffersLink = {
  key: 'yourOffers',
  messageKey: 'yourOffers',
  path: '/offers',
  name: 'offers',
};

export const customerDesktopNavItems = [
  { key: 'profileDropdown', messageKey: 'profileLink', path: '/profile', name: 'profile' },
];

export const protectionNavItems = [
  {
    key: 'product',
    messageKey: 'productHeading',
    name: 'Product',
    path: '/',
    children: [
      {
        key: 'electronics',
        messageKey: 'electronicsLink',
        name: 'Electronics',
        path: '/electronics-protection',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
      {
        key: 'Appliances',
        messageKey: 'appliancesLink',
        name: 'Appliances',
        path: '/appliances-protection',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
      {
        key: 'Furniture',
        messageKey: 'furnitureLink',
        name: 'Furniture',
        path: '/furniture-protection',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
      {
        key: 'Jewelry',
        messageKey: 'jewelryLink',
        name: 'Jewelry',
        path: '/jewelry-protection',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
      {
        key: 'Sports Equipment',
        messageKey: 'sportsEquipmentLink',
        name: 'Sports Equipment',
        path: '/sports-equipment-protection',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
      {
        key: 'Eyewear',
        messageKey: 'eyewearLink',
        name: 'Eyewear',
        path: '/eyewear-protection',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
    ],
  },
  {
    key: 'business',
    messageKey: 'businessHeading',
    name: 'Business',
    path: '/',
    children: [
      {
        key: 'Shake Shield',
        messageKey: 'shakeShieldLink',
        name: 'Shake Shield',
        path: '/shake-shield',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
    ],
  },
  {
    key: 'travel',
    messageKey: 'travelHeading',
    name: 'Travel',
    path: '/',
    children: [
      {
        key: 'travel',
        messageKey: 'travelLink',
        name: 'Travel',
        path: '/travel-protection',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
    ],
  },
  {
    key: 'other',
    messageKey: 'otherHeading',
    name: 'Other',
    path: '/',
    children: [
      {
        key: 'Ticket & Events',
        messageKey: 'ticketEventsLink',
        name: 'Ticket & Events',
        path: '/tickets-and-events-protection',
        tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
      },
    ],
  },
];

export const globalNavHelp = {
  key: 'help',
  messageKey: 'helpGlobalNavLink',
  path: '/help',
  name: 'Help',
  icon: IconHelp,
};

export const globalNavLogin = {
  key: 'login',
  messageKey: 'loginGlobalNavLink',
  name: 'Login',
  path: '/login',
  icon: IconCustomer,
};

export const globalNavAccount = {
  key: 'account',
  messageKey: 'accountGlobalNavLink',
  path: '/account',
  name: 'Account',
  icon: IconCustomer,
};

export const navProtection = {
  key: 'protection',
  messageKey: 'protectionNavLink',
  path: '/',
  name: 'Protection',
  icon: IconChevronDown,
};

export const navAPIAffiliates = {
  key: 'apiAffiliates',
  messageKey: 'apiAffiliatesLink',
  path: '/',
  name: 'API & Affiliates',
  icon: IconChevronDown,
};

export const publicNavItems = [
  {
    key: 'claims',
    messageKey: 'claimsGlobalNavLink',
    path: '/claim',
    name: 'Claims',
    tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
  },
  {
    key: 'APIs',
    messageKey: 'apisLink',
    name: 'APIs',
    path: '/partner-api',
    tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
  },
  {
    key: 'Affiliates',
    messageKey: 'affiliatesLink',
    name: 'Affiliates',
    path: '/affiliate-program',
    tag: isXCoverNextJSLive() ? ('a' as React.ElementType) : undefined,
  },
];
