import React, { lazy, Suspense } from 'react';
import { useIntl } from 'react-intl';
import { Loading } from '@whitelabel/component-library';
import { IPage } from '@whitelabel/helpers/types';
import { StyledLayout, StyledHeader, StyledMain } from './styledLayout';

const Footer = lazy(() => import('../../containers/layout/Footer'));

interface ILayoutProps {
  navBar?: React.ReactNode;
  footerType?: 'large' | 'slim' | 'slimer';
  disclaimer?: IPage;
  hideDisclaimer?: boolean;
  disclaimerSlug?: string;
  gray?: boolean;
  spacing?: 'lg';
  className?: string;
  children?: React.ReactNode;
  footer?: boolean | React.ReactElement;
  hideHeaderFooter?: boolean;
  hideFooterNav?: boolean;
  hasBottomBorder?: boolean;
  hasHeaderMarginBottom?: boolean;
}

const Layout = ({
  navBar,
  footerType = 'large',
  disclaimer,
  hideDisclaimer,
  disclaimerSlug = 'disclaimer',
  gray,
  spacing,
  className,
  children,
  footer,
  hideFooterNav,
  hasBottomBorder = true,
  hasHeaderMarginBottom = true,
  // rest of html attributes
  ...props
}: ILayoutProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledLayout $rtl={intl.bidi} $gray={gray} className={className} data-test-id="Layout" {...props}>
      {navBar && (
        <StyledHeader
          $hasHeaderMarginBottom={hasHeaderMarginBottom}
          $hasBottomBorder={hasBottomBorder}
          id="Header"
          $spacing={spacing}
          data-test-id="Header"
        >
          {navBar}
        </StyledHeader>
      )}

      <StyledMain id="Main" role="main" $spacing={spacing} data-test-id="Main">
        {children}
      </StyledMain>

      {footer ||
        (footer !== false && (
          <Suspense fallback={<Loading />}>
            <Footer
              footertype={footerType}
              hidedisclaimer={hideDisclaimer ? 'true' : 'false'}
              disclaimer={disclaimer}
              disclaimerSlug={disclaimerSlug}
              hidefooternav={hideFooterNav ? 'true' : 'false'}
            />
          </Suspense>
        ))}
    </StyledLayout>
  );
};

export default Layout;
