import * as FullStory from '@fullstory/browser';
import { valueOrNull } from '@whitelabel/helpers/utils';
import { getSessionStorage } from '@whitelabel/helpers/storageUtils';
import { CLAIM_JOURNEY_DATA, CLAIM_JOURNEY_ID, CONTACT_JOURNEY_ID } from './constants';
import { dateISOtoFullDateAndTime } from './date';
import {
  IBWClaimsJourneyTrackingArgs,
  IBWStoredClaimSelectorDataArgs,
  IBWClaimDetailsCommunicationsTrackingArgs,
  IBWRentalCoverBannerImpressionTrackingArgs,
  IBWRentalCoverBannerClickTrackingArgs,
  IBWCallUsClickTrackingArgs,
  IBWCESScoreTrackingArgs,
  IBWContactModalTrackingArgs,
  IBWFNOLSectionTimeTrackingArgs,
} from './types';

export const bwClaimsJourneyTracking = ({
  claimJourneyID,
  bookingID,
  partnerID,
  partnerName,
  subsidiaryID,
  subsidiaryName,
  policyTypeGroupName,
  policyName,
  claimSelectorName,
  decisionPointsName,
  decisionPointsAnswer,
  claimTypeName,
  sectionName,
  hasLeavedClaimJourney,
  completed,
  submitted,
  xclaimValidationError,
  fieldValidationErrorCount,
  hasClickedHelpCenter,
  hasClickedClaimSubmittedAlertLink,
  hasClickedClaimSubmittedCopyURL,
  hasClickedClaimSubmittedViewClaimButton,
  documentName,
  helpArticleSlug,
  validationErrorType,
  entryPoint,
}: IBWClaimsJourneyTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;

  if (!claimJourneyID) {
    sessionStorage.setItem(CLAIM_JOURNEY_ID, `${bookingID}-${dateISOtoFullDateAndTime(new Date().toISOString())}`);
  }

  const storedClaimSelectorData: IBWStoredClaimSelectorDataArgs =
    JSON.parse(localStorage.getItem(CLAIM_JOURNEY_DATA) as string) ?? {};
  const {
    policyName: storedPolicyName,
    claimSelectorName: storedClaimSelectorName,
    decisionPointsName: storedDecisionPointName,
    decisionPointsAnswer: storedDecisionPointsAnswer,
  } = storedClaimSelectorData;

  if (claimSelectorName && (policyName || (decisionPointsName && decisionPointsAnswer))) {
    localStorage.setItem(
      CLAIM_JOURNEY_DATA,
      JSON.stringify({
        claimSelectorName,
        policyName: policyName ?? valueOrNull(storedPolicyName),
        decisionPointsName,
        decisionPointsAnswer,
      }),
    );
  }

  window.bwtag('record', 'claims_journey', {
    claimJourneyID: getSessionStorage(CLAIM_JOURNEY_ID) ?? '',
    policyName: policyName ?? valueOrNull(storedPolicyName),
    claimSelectorName: claimSelectorName ?? valueOrNull(storedClaimSelectorName),
    decisionPointsName: decisionPointsName ?? valueOrNull(storedDecisionPointName),
    decisionPointsAnswer: decisionPointsAnswer ?? valueOrNull(storedDecisionPointsAnswer),
    claimTypeName: valueOrNull(claimTypeName),
    sectionName: valueOrNull(sectionName),
    hasLeavedClaimJourney: hasLeavedClaimJourney ?? false,
    completed: completed ?? false,
    submitted: submitted ?? false,
    hasClickedHelpCenter: hasClickedHelpCenter ?? false,
    hasClickedClaimSubmittedAlertLink: hasClickedClaimSubmittedAlertLink ?? false,
    hasClickedClaimSubmittedViewClaimButton: hasClickedClaimSubmittedViewClaimButton ?? false,
    hasClickedClaimSubmittedCopyURL: hasClickedClaimSubmittedCopyURL ?? false,
    xclaimValidationError: valueOrNull(xclaimValidationError),
    fieldValidationErrorCount,
    fsSession: FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'NA',
    bookingID,
    ins: bookingID,
    documentName,
    helpArticleSlug,
    validationErrorType,
    partner: partnerName,
    partnerID,
    partnerName,
    subsidiaryID,
    subsidiaryName,
    policyTypeGroupName,
    entryPoint,
  });
};

export const bwClaimDetailsCommunicationsTracking = ({
  link,
  bookingID,
  claimReference,
  status,
  dateSent,
  linkText,
  emailTemplateId,
  emailTemplateSlug,
  partnerID,
  partnerName,
  subsidiaryID,
  subsidiaryName,
  policyTypeGroupName,
}: IBWClaimDetailsCommunicationsTrackingArgs): void => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'claim_communications', {
    link,
    bookingID,
    ins: bookingID,
    claimReference,
    claim_cla: claimReference,
    status,
    dateSent,
    linkText,
    emailTemplateId,
    emailTemplateSlug,
    fsSession: FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'FS Not Ready',
    partnerID,
    partnerName,
    subsidiaryID,
    subsidiaryName,
    policyTypeGroupName,
  });
};

export const bwPASHTracking = (anchorID: string, partnerName: string, languageCode: string) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'click', {
    type: 'anchor_id',
    value: anchorID,
    partner_name: partnerName,
    language_code: languageCode,
  });
};

export const bwRentalCoverBannerImpressionTracking = ({
  domID,
  CountryOfTravelCode,
  CustomerCountryCode,
  FromDate,
  ToDate,
  PolicyType,
}: IBWRentalCoverBannerImpressionTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'impression', {
    domID,
    CountryOfTravelCode,
    CustomerCountryCode,
    FromDate,
    ToDate,
    PolicyType,
  });
};

export interface IBWImpressionTrackingArgs {
  analyticsID: string;
  [key: string]: string | string[] | undefined;
}

export const bwImpressionTracking = ({ analyticsID, ...props }: IBWImpressionTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'impression', {
    analyticsID,
    ...props,
  });
};

export const bwRentalCoverBannerClickTracking = ({
  bannerID,
  domID,
  CountryOfTravelCode,
  CustomerCountryCode,
  FromDate,
  ToDate,
  PolicyType,
}: IBWRentalCoverBannerClickTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'click', {
    bannerID,
    domID,
    CountryOfTravelCode,
    CustomerCountryCode,
    FromDate,
    ToDate,
    PolicyType,
  });
};

export const bwCallUsClickTracking = ({ partnerSlug }: IBWCallUsClickTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'click', {
    analyticsID: 'call-us-button',
    partnerSlug,
  });
};

export const bwCESScoreTracking = ({
  source,
  score,
  bookingID,
  claimReference,
  partnerName,
  partnerID,
  subsidiaryID,
  subsidiaryName,
  articleSlug,
  feedback,
  reviewed,
  policyTypeGroupName,
}: IBWCESScoreTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', source, {
    score,
    bookingReference: bookingID ?? null,
    ins: bookingID,
    claim_cla: claimReference,
    claimReference: claimReference ?? null,
    articleSlug: articleSlug ?? null,
    feedback: feedback ?? null,
    reviewed: reviewed ?? false,
    fsSession: FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'NA',
    partnerSlug: partnerName,
    partnerName,
    partnerID,
    subsidiaryID,
    subsidiaryName,
    policyTypeGroupName,
  });
};

export const bwContactModalTracking = ({
  contactJourneyID,
  bookingReference,
  claimReference,
  partnerName,
  partnerID,
  subsidiaryID,
  subsidiaryName,
  contactPurpose,
  pageSlug,
  articleTitle,
  hasClosedContactModal,
  hasClickedReturnPreviousStep,
  hasClickedHaveMoreQuestionsButton,
  hasClickedViewAllArticlesButton,
  hasClickedSubmitEnquiryButton,
  hasSubmitEnquirySuccessfully,
  hasOpenedContactModal,
}: IBWContactModalTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;
  if (!contactJourneyID) {
    sessionStorage.setItem(CONTACT_JOURNEY_ID, new Date().toISOString());
  }

  window.bwtag('record', 'contact_journey', {
    contactJourneyID: contactJourneyID ?? getSessionStorage(CONTACT_JOURNEY_ID),
    bookingReference: valueOrNull(bookingReference),
    contactPurpose: valueOrNull(contactPurpose),
    pageSlug: valueOrNull(pageSlug),
    articleTitle: valueOrNull(articleTitle),
    hasClosedContactModal: hasClosedContactModal ?? false,
    hasClickedReturnPreviousStep: hasClickedReturnPreviousStep ?? false,
    hasClickedHaveMoreQuestionsButton: hasClickedHaveMoreQuestionsButton ?? false,
    hasClickedViewAllArticlesButton: hasClickedViewAllArticlesButton ?? false,
    hasClickedSubmitEnquiryButton: hasClickedSubmitEnquiryButton ?? false,
    hasSubmitEnquirySuccessfully: hasSubmitEnquirySuccessfully ?? false,
    hasOpenedContactModal: hasOpenedContactModal ?? false,
    fsSession: FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'NA',
    ins: bookingReference,
    claimReference,
    claim_cla: claimReference,
    partnerName,
    partnerID,
    subsidiaryID,
    subsidiaryName,
  });
};

export const bwFNOLSectionTimeTracking = ({
  bookingID,
  partnerID,
  partnerName,
  subsidiaryID,
  subsidiaryName,
  policyTypeGroupName,
  timeSpent,
  claimJourneyID,
  claimTypeID,
  entryPoint,
  sectionName,
  claimTypeName,
  documentName,
}: IBWFNOLSectionTimeTrackingArgs) => {
  if (window.isHeadless || !window.bwtag) return;

  window.bwtag('record', 'fnol-section-time', {
    ins: bookingID,
    bookingID,
    partnerID,
    partnerName,
    subsidiaryID,
    subsidiaryName,
    timeSpent,
    policyTypeGroupName,
    claimJourneyID,
    claimTypeID,
    claimTypeName,
    entryPoint,
    sectionName,
    documentName,
  });
};
