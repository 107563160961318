import React, { useMemo, ChangeEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'reactstrap';
import { get } from 'lodash/fp';
import { FormikErrors, FormikHelpers } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import { site } from '@whitelabel/helpers/site';
import { countriesToSelectOptions } from '@whitelabel/helpers/forms/transform';
import {
  ILabelValuePair,
  IAddressInputFields,
  IManualAddressFieldsOverride,
  IAddressFieldInfo,
} from '@whitelabel/helpers/types';
import { getReactSelectValue, checkDynamicName } from '@whitelabel/helpers/utils';
import FieldFeedback from '../FieldFeedback';
import { StyledSelect } from '../../styled/StyledSelect';
import { StyledDescription } from '../../styled/StyledFormText';
import { getStatesOfCountry, getDynamicFieldValue } from '../../helpers/transform';
import { StyledLabel } from '../InputLabel/styledInputLabel';
import { StyledManualAddressInput } from './styledManualAddressInput';
import messages from './messages';

export interface IManualAddressInputProps {
  name: string;
  value?: IAddressInputFields;
  setFieldValue: FormikHelpers<IAddressInputFields>['setFieldValue'];
  setFieldTouched: FormikHelpers<IAddressInputFields>['setFieldTouched'];
  errors?: FormikErrors<IAddressInputFields>;
  invalid?: boolean;
  placeholder?: string;
  setGlobalError?: (...args: any[]) => void;
  disabled?: boolean;
  className?: string;
  fieldsOverride?: IManualAddressFieldsOverride;
  hideAddressLine1?: boolean;
  hideAddressLine2?: boolean;
  nameList?: IAddressFieldInfo<string>;
  labelList?: IAddressFieldInfo<string | undefined>;
  descriptionList?: IAddressFieldInfo<string | undefined>;
  placeholderList?: IAddressFieldInfo<string | undefined>;
}

const ManualAddressInput = ({
  className,
  name,
  value: fieldValue,
  errors,
  invalid,
  setFieldTouched,
  setFieldValue,
  fieldsOverride,
  hideAddressLine1 = false,
  hideAddressLine2 = false,
  nameList,
  labelList,
  descriptionList,
  placeholderList,
}: IManualAddressInputProps): JSX.Element => {
  const { formatMessage, locale } = useIntl();
  const countryOptions = useMemo(() => countriesToSelectOptions(site.countries, locale), []);

  const address2Field = checkDynamicName('address2', nameList);
  const cityField = checkDynamicName('city', nameList);
  const regionField = checkDynamicName('region', nameList);
  const countryField = checkDynamicName('country', nameList);
  const postcodeField = checkDynamicName('postcode', nameList);

  const countryValue = fieldValue?.[countryField as keyof IAddressInputFields];

  const handleTextInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name: targetName, value: targetValue } = event.target;
    setFieldValue(name, { ...fieldValue, [targetName]: targetValue });
  };

  const handleInputBlur = () => {
    setFieldTouched(name, true);
  };

  const handleCountryChange = (option: ILabelValuePair | null) => {
    if (option) {
      setFieldValue(name, {
        ...fieldValue,
        [countryField]: option.value,
        [regionField]: '',
      });
    }
  };
  const handleStateChange = (option: ILabelValuePair | null) => {
    if (option) {
      setFieldValue(name, { ...fieldValue, [regionField]: option.value });
    }
  };

  const regionOptions = useMemo(() => getStatesOfCountry(countryValue, locale), [countryValue, locale]);
  const shouldShowDropdownForState = useMemo(() => ['AU', 'US', 'CA'].includes(countryValue ?? ''), [countryValue]);

  return (
    <StyledManualAddressInput className={className} $hideAddressLine1={hideAddressLine1}>
      {!hideAddressLine1 && (
        <div className="input-wrapper">
          <StyledLabel htmlFor={`${name}-address-line-1`}>{formatMessage(messages.addressLine1)}</StyledLabel>
          <Input
            id={`${name}-address-line-1`}
            // @ts-ignore
            type="text"
            name="address1"
            autoComplete="address-line1"
            onChange={handleTextInputChange}
            onBlur={handleInputBlur}
            value={fieldValue?.address1}
            {...(fieldsOverride?.address1 ?? {})}
          />
        </div>
      )}
      {!hideAddressLine2 && (
        <div className="input-wrapper">
          <StyledLabel
            className={invalid && !!get(`${name}.${address2Field}`, errors) ? 'text-danger form-label' : 'form-label'}
            htmlFor={`${name}-${address2Field}`}
          >
            {labelList?.address2 ?? formatMessage(messages.addressLine2)}
          </StyledLabel>
          {descriptionList?.address2 && (
            <StyledDescription>{ReactHtmlParser(descriptionList.address2)}</StyledDescription>
          )}
          <Input
            id={`${name}-${address2Field}`}
            // @ts-ignore
            type="text"
            name={address2Field}
            autoComplete="address-line2"
            onChange={handleTextInputChange}
            onBlur={handleInputBlur}
            value={getDynamicFieldValue('address2', nameList, fieldValue)}
            placeholder={placeholderList?.address2}
            {...(fieldsOverride?.address2 ?? {})}
          />
          {invalid && <FieldFeedback error={get(`${name}.${address2Field}`, errors) as string} />}
        </div>
      )}
      <div className="input-wrapper">
        <StyledLabel
          className={invalid && !!get(`${name}.${countryField}`, errors) ? 'text-danger form-label' : 'form-label'}
          htmlFor={`${name}-${countryField}`}
        >
          {labelList?.country ?? formatMessage(messages.country)}
        </StyledLabel>
        {descriptionList?.country && <StyledDescription>{ReactHtmlParser(descriptionList.country)}</StyledDescription>}
        <StyledSelect
          id={`${name}-${countryField}`}
          name={countryField}
          classNamePrefix="react-select"
          autoComplete="country"
          options={countryOptions}
          onChange={handleCountryChange}
          onBlur={handleInputBlur}
          value={getReactSelectValue(getDynamicFieldValue('country', nameList, fieldValue), countryOptions)}
          placeholder={placeholderList?.country}
          {...(fieldsOverride?.country ?? {})}
        />
        {invalid && <FieldFeedback error={get(`${name}.${countryField}`, errors) as string} />}
      </div>
      <div className="input-wrapper">
        <StyledLabel
          className={invalid && !!get(`${name}.${regionField}`, errors) ? 'text-danger form-label' : 'form-label'}
          htmlFor={`${name}-${regionField}`}
        >
          {labelList?.region ?? formatMessage(messages.stateRegion)}
        </StyledLabel>
        {descriptionList?.region && <StyledDescription>{ReactHtmlParser(descriptionList.region)}</StyledDescription>}
        {shouldShowDropdownForState ? (
          <StyledSelect
            id={`${name}-${regionField}`}
            name={regionField}
            autoComplete="off"
            classNamePrefix="react-select"
            options={regionOptions}
            onChange={handleStateChange}
            value={getReactSelectValue(getDynamicFieldValue('region', nameList, fieldValue), regionOptions)}
            placeholder={placeholderList?.region}
            {...(fieldsOverride?.region ?? {})}
          />
        ) : (
          <Input
            id={`${name}-${regionField}`}
            name={regionField}
            type="text"
            autoComplete="off"
            onChange={handleTextInputChange}
            onBlur={handleInputBlur}
            value={getDynamicFieldValue('region', nameList, fieldValue)}
            invalid={invalid && !!get(`${name}.${regionField}`, errors)}
            placeholder={placeholderList?.region}
            {...(fieldsOverride?.region ?? {})}
          />
        )}
        {invalid && <FieldFeedback error={get(`${name}.${regionField}`, errors) as string} />}
      </div>
      <div className="input-wrapper">
        <StyledLabel
          className={invalid && !!get(`${name}.${cityField}`, errors) ? 'text-danger form-label' : 'form-label'}
          htmlFor={`${name}-${cityField}`}
        >
          {labelList?.city ?? formatMessage(messages.city)}
        </StyledLabel>
        {descriptionList?.city && <StyledDescription>{ReactHtmlParser(descriptionList.city)}</StyledDescription>}
        <Input
          id={`${name}-${cityField}`}
          name={cityField}
          // @ts-ignore
          type="text"
          autoComplete="address-level2"
          onChange={handleTextInputChange}
          onBlur={handleInputBlur}
          value={getDynamicFieldValue('city', nameList, fieldValue)}
          invalid={invalid && !!get(`${name}.${cityField}`, errors)}
          placeholder={placeholderList?.city}
          {...(fieldsOverride?.city ?? {})}
        />
        {invalid && <FieldFeedback error={get(`${name}.${cityField}`, errors) as string} />}
      </div>
      <div className="input-wrapper">
        <StyledLabel
          className={invalid && !!get(`${name}.${postcodeField}`, errors) ? 'text-danger form-label' : 'form-label'}
          htmlFor={`${name}-${postcodeField}`}
        >
          {labelList?.postcode ?? formatMessage(messages.postcode)}
        </StyledLabel>
        {descriptionList?.postcode && (
          <StyledDescription>{ReactHtmlParser(descriptionList.postcode)}</StyledDescription>
        )}
        <Input
          id={`${name}-${postcodeField}`}
          name={postcodeField}
          // @ts-ignore
          type="text"
          autoComplete="postal-code"
          onChange={handleTextInputChange}
          onBlur={handleInputBlur}
          value={getDynamicFieldValue('postcode', nameList, fieldValue)}
          invalid={invalid && !!get(`${name}.${postcodeField}`, errors)}
          placeholder={placeholderList?.postcode}
          {...(fieldsOverride?.postcode ?? {})}
        />
        {invalid && <FieldFeedback error={get(`${name}.${postcodeField}`, errors) as string} />}
      </div>
    </StyledManualAddressInput>
  );
};

export default ManualAddressInput;
