import * as SentryReact from '@sentry/react';
import { configureStore } from '@reduxjs/toolkit';
import { IntlState } from 'react-intl-redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { rtkQueryErrorLogger } from '@whitelabel/xcover-shared/store/middlewares/ApiErrorLogger';
import appReducers from '../reducers';
import { customer } from './slices/customer';
import { user } from './slices/user';

export const history = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = SentryReact.createReduxEnhancer({});

export const makeStore = (intl: IntlState) => {
  const routerReducer = connectRouter(history);
  return configureStore({
    reducer: {
      router: routerReducer,
      ...appReducers,
      customer,
      user,
      [xcoverAPI.reducerPath]: xcoverAPI.reducer,
    },
    preloadedState: { intl },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(sagaMiddleware)
        .concat(routerMiddleware(history))
        .concat(xcoverAPI.middleware)
        .concat(rtkQueryErrorLogger),
    enhancers: (defaultEnhancers) => defaultEnhancers.concat(sentryReduxEnhancer),
  });
};

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
