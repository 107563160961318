import React from 'react';
import { locales } from '@whitelabel/helpers/locale';
import { IBooking } from '@whitelabel/helpers/types';
import {
  YOUR_PROTECTION_LINK,
  YOUR_OFFERS_LINK,
  MAKE_A_CLAIM_LINK,
  EXPERIMENT_OFFERS_FEATURE_FLAG_NAME,
} from '@whitelabel/xcover-shared/helpers/constants';
import { INavItem, FeatureFlag } from '../../../helpers/types';
import { StyledNavIconWrapper, StyledNavIcon } from './styledNavBar';
import { accountDropdownLink, claimsDropdownLink, profileDropdownLink, yourOffersLink } from './navItems';

export const getGlobalNavItems = ({ NavItem, globalNavHelp, closeNav, toggleLocaleModal, locale }: any) => (
  <>
    <NavItem item={globalNavHelp} onClick={closeNav} />
    <li>
      <button onClick={toggleLocaleModal} type="button">
        <StyledNavIconWrapper className="nav-icon-wrapper">
          <StyledNavIcon as={locales[locale].Flag} />
        </StyledNavIconWrapper>
        {locales[locale].nameLocal}
      </button>
    </li>
  </>
);

export const shouldShowOffersLink = (bookings: IBooking[], featureFlagsData?: FeatureFlag): boolean => {
  if (!featureFlagsData) {
    return false;
  }

  const supportedPartnerList = featureFlagsData[EXPERIMENT_OFFERS_FEATURE_FLAG_NAME] as string[];

  if (!supportedPartnerList || !supportedPartnerList[0]) {
    return false;
  }

  return !!bookings.find((booking) => supportedPartnerList.includes(booking.partner.id));
};

export const getMobileNavItems = ({
  hasLoggedIn,
  items,
  showOffersLink,
}: {
  hasLoggedIn: boolean;
  items: INavItem[];
  showOffersLink: boolean;
}) => {
  let navItems: INavItem[] = [];
  if (hasLoggedIn) {
    navItems = [accountDropdownLink, claimsDropdownLink];
    if (showOffersLink) {
      navItems.push(yourOffersLink);
    }
    navItems.push(profileDropdownLink);
  } else {
    navItems = [...items];
  }
  return navItems;
};

export const getMainNavItems = ({
  hasLoggedIn,
  items,
  showOffersLink,
}: {
  hasLoggedIn: boolean;
  items: INavItem[];
  showOffersLink: boolean;
}) => {
  let navItems: INavItem[] = [];
  if (hasLoggedIn) {
    navItems = [YOUR_PROTECTION_LINK, MAKE_A_CLAIM_LINK];
    if (showOffersLink) {
      navItems.push(YOUR_OFFERS_LINK);
    }
  } else {
    navItems = [...items];
  }

  return navItems;
};
