import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { IUser } from '@whitelabel/helpers/types';
import { IError } from '@whitelabel/xcover-shared/helpers/types';

import {
  globalCheckSession,
  localCheckSession,
  confirmUnmaskedSignUp,
  forgotPassword,
  forgotPasswordSubmit,
} from '../actions/user';
import {
  confirmSignUpFulfill,
  confirmSignUpRequest,
  confirmSignUpSuccess,
  impersonateLoginFailure,
  impersonateLoginFulfill,
  impersonateLoginRequest,
  impersonateLoginSuccess,
  loginFailure,
  loginFulfill,
  loginRequest,
  loginSuccess,
  logoutFailure,
  logoutRequest,
  logoutSuccess,
  resetError,
} from './createActions';

export interface IUserState {
  data: null | IUser;
  loading: boolean;
  error: IError | null;
  checkingSession: boolean;
  checkSessionSuccessful: boolean;
  forgotPasswordSubmitError: IError | null;
  signingIn: boolean;
}

export const initialState: IUserState = {
  data: null,
  loading: false,
  error: null,
  checkingSession: true,
  checkSessionSuccessful: false,
  forgotPasswordSubmitError: null,
  signingIn: false,
};

export const user = createReducer(initialState, (builder) => {
  builder
    .addCase(globalCheckSession.pending, (state) => {
      state.data = null;
      state.checkingSession = true;
      state.error = null;
    })

    .addCase(forgotPasswordSubmit.rejected, (state, action) => {
      state.forgotPasswordSubmitError = action.payload as IError;
    })
    .addMatcher(isAnyOf(loginRequest, confirmSignUpRequest, confirmUnmaskedSignUp.pending), (state) => {
      state.data = null;
      state.error = null;
      state.loading = true;
      state.forgotPasswordSubmitError = null;
      state.signingIn = true;
    })
    .addMatcher(isAnyOf(impersonateLoginRequest, forgotPassword.pending, forgotPasswordSubmit.pending), (state) => {
      state.data = null;
      state.error = null;
      state.loading = true;
      state.forgotPasswordSubmitError = null;
    })
    .addMatcher(isAnyOf(resetError, logoutRequest), (state) => {
      state.error = null;
    })
    .addMatcher(
      isAnyOf(
        globalCheckSession.fulfilled,
        localCheckSession.fulfilled,
        loginSuccess,
        impersonateLoginSuccess,
        confirmSignUpSuccess,
      ),
      (state, action) => {
        state.data = action.payload as IUser;
      },
    )
    .addMatcher(isAnyOf(globalCheckSession.rejected, localCheckSession.rejected, logoutSuccess), (state) => {
      state.data = null;
    })

    .addMatcher(
      isAnyOf(
        loginFailure,
        impersonateLoginFailure,
        logoutFailure,
        confirmUnmaskedSignUp.rejected,
        forgotPassword.rejected,
      ),
      (state, action) => {
        state.error = action.payload as IError;
      },
    )
    .addMatcher(
      isAnyOf(loginFulfill, confirmSignUpFulfill, confirmUnmaskedSignUp.fulfilled, confirmUnmaskedSignUp.rejected),
      (state) => {
        state.loading = false;
        state.signingIn = false;
      },
    )
    .addMatcher(
      isAnyOf(
        impersonateLoginFulfill,
        forgotPassword.fulfilled,
        forgotPassword.rejected,
        forgotPasswordSubmit.fulfilled,
        forgotPasswordSubmit.rejected,
      ),
      (state) => {
        state.loading = false;
      },
    )
    .addMatcher(isAnyOf(globalCheckSession.fulfilled, globalCheckSession.rejected), (state) => {
      state.checkingSession = false;
    })
    .addMatcher(isAnyOf(globalCheckSession.rejected, localCheckSession.rejected), (state) => {
      state.checkSessionSuccessful = false;
    })
    .addMatcher(isAnyOf(globalCheckSession.fulfilled, localCheckSession.fulfilled), (state) => {
      state.checkSessionSuccessful = true;
    });
});
