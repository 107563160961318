import { push } from 'connected-react-router';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import 'aws-amplify/auth/enable-oauth-listener';
import { mapKeys, camelCase } from 'lodash/fp';
import * as FullStory from '@fullstory/browser';
import { FormikHelpers, FormikValues } from 'formik';
import { updateIntl } from 'react-intl-redux';
import { IMPERSONATOR_TOKEN } from '@whitelabel/helpers/constants';
import {
  ERROR_CODE,
  IMPERSONATE,
  KW_FE_MESSAGES_SLUG,
  SOURCE_EMAIL,
  SOURCE_SMS,
  SKIP_SUMMARY_PAGE,
  CUSTOMER_REGION,
  ZENDESK_CHAT_PARTNER,
  REGEX_UUID,
  SIGNOUT_REDIRECT_TO_URL,
  LWA_LOCALE,
} from '@whitelabel/xcover-shared/helpers/constants';
import api, { handleFormikAWSFormError } from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost, getStoredCustomerRegion } from '@whitelabel/xcover-shared/helpers/multiRegion';
import {
  getCurrentUserDetails,
  isRedirectLinkAllowed,
  isXCoverNextJSLive,
} from '@whitelabel/xcover-shared/helpers/utils';
import { ICustomer } from '@whitelabel/helpers/types';
import { hasCookieYesMarketingAccepted } from '../../helpers/utils';
import { clearPDS } from '../services/xcover/endpoints/pds';
import { ILocationState, SignUpError } from '../../helpers/types';
import { clearBookings } from '../services/xcover/endpoints/bookings';
import { clearHelpArticle } from '../services/xcover/endpoints/help';
import { clearCOI } from '../services/xcover/endpoints/coi';
import { clearQuotes, quotesAPI } from '../services/xcover/endpoints/quotes';
import { loadFEMessages } from '../../helpers/locales';
import { clearPartners } from '../services/xcover/endpoints/partners';
import { clearCustomer } from '../slices/customer';
import {
  confirmSignUpFulfill,
  confirmSignUpRequest,
  confirmSignUpSuccess,
  loginFailure,
  loginFulfill,
  loginRequest,
  loginSuccess,
  logoutFailure,
  logoutRequest,
  logoutSuccess,
} from '../slices/createActions';
import type { AppDispatch, RootState } from '..';
import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { getCustomer, transferBookings, updateCustomerGDPR, cognitoSignIn } from './customer';

export const getUserAttributes = (attributes: any, username?: string) => {
  if (!username || (!attributes?.preferred_username && !REGEX_UUID.test(username))) {
    return undefined;
  }

  return mapKeys(camelCase, {
    username: attributes?.preferred_username || username,
    ...attributes,
  });
};

const clearImpersonateValues = () => {
  sessionStorage.removeItem(IMPERSONATE.CUSTOMER_ID);
  sessionStorage.removeItem(IMPERSONATE.CUSTOMER_EMAIL);
  sessionStorage.removeItem(IMPERSONATOR_TOKEN);
  sessionStorage.removeItem(IMPERSONATE.ADMIN_USERNAME);
  localStorage.removeItem(CUSTOMER_REGION);
};

function identifyUser(
  username: string,
  { id, firstName, lastName, email }: { id: string; firstName: string; lastName: string; email: string },
) {
  if (!window.isHeadless) {
    window.bwtag('identify', username);
    if (FullStory.isInitialized()) {
      FullStory.identify(id, {
        displayName: `${firstName} ${lastName}`,
        email,
      });
    }
  }
}

export const checkSession = async (
  payload: { bypassCognitoCache: boolean } | undefined,
  { rejectWithValue, dispatch }: any,
) => {
  try {
    const bypassCognitoCache = payload?.bypassCognitoCache;

    const id = sessionStorage.getItem(IMPERSONATE.CUSTOMER_ID);
    const email = sessionStorage.getItem(IMPERSONATE.CUSTOMER_EMAIL);
    // check session for 'login as customer' flow
    if (id) {
      const user = {
        username: id,
        email,
      };
      await dispatch(getCustomer({ id: user.username })).unwrap();
      return user;
    }

    if (getStoredCustomerRegion()) {
      let user;
      // usual check session flow
      await fetchAuthSession({ forceRefresh: bypassCognitoCache });

      const { cognitoUser, cognitoAttributes } = await getCurrentUserDetails();

      if (cognitoUser) {
        user = getUserAttributes(cognitoAttributes, cognitoUser.username);
      }

      if (user) {
        await dispatch(getCustomer({ id: user.username })).unwrap();
        return user;
      }
    }
    return null;
  } catch (error: any) {
    if (error.name === 'UserUnAuthenticatedException') {
      return null;
    }
    return rejectWithValue(error);
  }
};

export const globalCheckSession = createAppAsyncThunk('user/globalCheckSession', checkSession);
export const localCheckSession = createAppAsyncThunk('user/localCheckSession', checkSession);

export const redirectAfterLogIn =
  ({
    redirect,
    redirectParam,
    customer,
  }: {
    redirect?: { pathname: string; search?: string };
    redirectParam?: string | null;
    customer: ICustomer;
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (redirectParam && isRedirectLinkAllowed(redirectParam)) {
      // Trigger a reload so the request goes to the nextJs server
      window.location.replace(redirectParam);
    } else if (!redirect?.pathname || redirect.pathname?.endsWith('/account')) {
      const { intl } = getState();
      const { locale, messages } = intl;
      try {
        const {
          // @ts-ignore
          data: { all: quotes },
          error,
        } = await dispatch(quotesAPI.endpoints.getCustomerQuotes.initiate({ id: customer.id, locale }));

        if (error) {
          throw error;
        }
        if (
          quotes &&
          Object.keys(quotes).length &&
          !Object.keys(quotes).includes(localStorage.getItem(SKIP_SUMMARY_PAGE) as string)
        ) {
          const feMessages = await loadFEMessages(locale, KW_FE_MESSAGES_SLUG);

          dispatch(
            updateIntl({
              locale,
              messages: { ...messages, ...feMessages },
            }),
          );
          dispatch(push(`/${locale}/summary`));
        } else {
          dispatch(push(`/${locale}/account`));
        }
      } catch (error) {
        dispatch(push(`/${locale}/account`));
      }
    } else {
      dispatch(push(redirect));
    }
  };

export const updateGDPRConsent = (customer: ICustomer) => async (dispatch: AppDispatch) => {
  const gdprConsent = hasCookieYesMarketingAccepted();
  if ((gdprConsent && !customer.gdprConsent) || (!gdprConsent && customer.gdprConsent)) {
    dispatch(
      updateCustomerGDPR({
        id: customer.id,
        gdprConsent,
      }),
    );
  }
};
/* We can't use creatAsyncThunk here, because if user is returned at the bottom, api call in 'redirectAfterLogIn' won't get user info. 
If user is returned early, then the codes afterwards will not excute */

export const login =
  ({
    cognitoUserID,
    password,
    setSubmitting,
    transferBookingsData = null,
    shouldRedirect = true,
    callbackOnSuccess,
  }: {
    cognitoUserID: string;
    password: string;
    setSubmitting?: FormikHelpers<FormikValues>['setSubmitting'];
    transferBookingsData?: { customerID: string; securityToken: string } | null;
    shouldRedirect?: boolean;
    callbackOnSuccess?: () => void;
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      sessionStorage.removeItem(IMPERSONATOR_TOKEN);
      dispatch(loginRequest());
      await cognitoSignIn({ cognitoUserID, password, getState });
      const { cognitoUser, cognitoAttributes } = await getCurrentUserDetails();
      const user: any = getUserAttributes(cognitoAttributes, cognitoUser?.username);

      await dispatch(getCustomer({ id: user.username })).unwrap();
      const customer = getState().customer.data as { id: string; firstName: string; lastName: string; email: string };

      identifyUser(user.username, customer);

      if (transferBookingsData) {
        await dispatch(
          transferBookings({
            targetCustomerID: customer?.id,
            customerID: transferBookingsData.customerID,
            securityToken: transferBookingsData.securityToken,
          }),
        );
      }

      dispatch(updateGDPRConsent(customer));
      dispatch(loginSuccess(user));

      if (callbackOnSuccess) {
        setSubmitting?.(false);
        callbackOnSuccess();
      } else if (shouldRedirect) {
        const redirect = (getState().router.location.state as ILocationState)?.referrer as
          | { pathname: string; search?: string | undefined }
          | undefined;
        const { search } = getState().router.location;
        const redirectParam = new URLSearchParams(search).get('redirect');
        await dispatch(redirectAfterLogIn({ redirect, customer, redirectParam }));
      } else {
        setSubmitting?.(false);
      }
    } catch (error) {
      dispatch(loginFailure(handleFormikAWSFormError(error)));
      setSubmitting?.(false);
    } finally {
      dispatch(loginFulfill());
    }
  };

export const postLogin = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const customer = getState().customer.data as { id: string; firstName: string; lastName: string; email: string };
  identifyUser(customer.id, customer);
  dispatch(updateGDPRConsent(customer));
};

export const postLogout = () => {
  if (!window.isHeadless) window.bwtag('reset');
  if (sessionStorage.getItem(IMPERSONATOR_TOKEN)) clearImpersonateValues();

  sessionStorage.removeItem(ZENDESK_CHAT_PARTNER);
};

export const logout = (redirect?: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch(logoutRequest());
    if (redirect) {
      sessionStorage.setItem(SIGNOUT_REDIRECT_TO_URL, redirect);
    } else {
      const { locale } = getState().intl;
      sessionStorage.setItem(LWA_LOCALE, locale);
    }

    await signOut();

    postLogout();

    if (redirect && isXCoverNextJSLive()) {
      window.location.replace(redirect);
    }
    // clearCustomer needs to put after clearPartners,
    // or else, it might re-fetch partners after logout on help page
    dispatch(clearPartners());
    dispatch(clearCustomer());
    dispatch(clearBookings());
    dispatch(clearQuotes());
    dispatch(clearCOI());
    dispatch(clearPDS());
    dispatch(clearHelpArticle());
    dispatch(logoutSuccess());

    if (redirect) {
      dispatch(push(redirect));
    }
  } catch (error) {
    dispatch(logoutFailure(error));
  }
};
export const signUpWithJWTToken = async ({
  id,
  signUpToken,
  password,
  email,
  phone,
  locale,
  securityToken,
}: {
  id: string;
  email?: string;
  password: string;
  phone?: string;
  signUpToken: string;
  locale: string;
  securityToken: string;
}) => {
  const searchParams = new URLSearchParams({ language: locale, security_token: securityToken });
  const body: { token: string; password: string; email?: string; phone?: string } = {
    token: signUpToken,
    password,
  };
  if (email) {
    body.email = email;
  }
  if (phone) {
    body.phone = phone;
  }
  await api.post(`${getAPIHost()}/customers/${id}/signup_confirm/?${searchParams}`, false, {
    body: JSON.stringify(body),
  });
};

export const signUp = async ({
  source,
  password,
  email,
  phone,
  id,
  token,
  locale,
}: {
  source: typeof SOURCE_EMAIL | typeof SOURCE_SMS;
  password: string;
  email?: string;
  phone?: string;
  id: string;
  token: string;
  locale: string;
}) => {
  const searchParams = new URLSearchParams({ language: locale, security_token: token });
  const body: {
    source: typeof SOURCE_EMAIL | typeof SOURCE_SMS;
    password: string;
    email?: string;
    phone?: string;
  } = {
    source,
    password,
  };
  if (email) {
    body.email = email;
  }
  if (phone) {
    body.phone = phone;
  }
  await api.post(`${getAPIHost()}/customers/${id}/account/?${searchParams}`, false, {
    body: JSON.stringify(body),
  });
};

export const confirmSignUp =
  ({
    id,
    email,
    password,
    phone,
    setSubmitting,
    source,
    setErrors,
    signUpToken,
  }: {
    id: string;
    email?: string;
    password: string;
    phone?: string;
    setSubmitting: FormikHelpers<FormikValues>['setSubmitting'];
    source: typeof SOURCE_EMAIL | typeof SOURCE_SMS;
    setErrors: (errors: SignUpError) => void;
    signUpToken: string | null | undefined;
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    let redirectParam;
    try {
      dispatch(confirmSignUpRequest());
      const { locale } = getState().intl;
      const urlParams = new URLSearchParams(getState().router.location.search);
      const token = urlParams.get('token');
      const securityToken = urlParams.get('securityToken');

      if (signUpToken) {
        await signUpWithJWTToken({
          id,
          email,
          password,
          phone,
          signUpToken,
          locale,
          securityToken: securityToken!,
        });
      } else {
        await signUp({ source, password, email, phone, id, token: token!, locale });
      }
      await cognitoSignIn({ cognitoUserID: id, password, getState });
      const { cognitoUser, cognitoAttributes } = await getCurrentUserDetails();
      const user: any = getUserAttributes(cognitoAttributes, cognitoUser?.username);
      dispatch(confirmSignUpSuccess(user));

      await dispatch(getCustomer({ id: user.username })).unwrap();

      const customer = getState().customer.data as { id: string; firstName: string; lastName: string; email: string };

      identifyUser(user.username, customer);

      dispatch(updateGDPRConsent(customer));
      const redirect = (getState().router.location.state as ILocationState)?.referrer;
      const { search, pathname } = redirect || {};
      const stateSearch = new URLSearchParams(search);
      stateSearch.delete('signup_token');
      stateSearch.delete('id');

      let redirectState;
      if (stateSearch.get('redirect')) {
        stateSearch.delete('redirect');
        const queryParam = stateSearch.toString() ? `?${stateSearch.toString()}` : '';
        redirectParam = pathname + queryParam;
      } else {
        redirectState = {
          pathname,
          search: stateSearch.toString() ? `?${stateSearch.toString()}` : '',
        };
      }
      await dispatch(redirectAfterLogIn({ redirect: redirectState, customer, redirectParam }));
    } catch (error) {
      const { locale } = getState().intl;
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      if (error.code === ERROR_CODE.ALREADY_REGISTERED) {
        dispatch(push(`/${locale}/login`));
      }
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      const errors = error?.errors;
      if (errors && !errors._error) {
        if (errors.token === 'Invalid token: ExpiredSignatureError') {
          setErrors({ showTokenError: true });
          // eslint-disable-next-line no-underscore-dangle
        } else if (errors._non_field_errors) {
          // eslint-disable-next-line no-underscore-dangle
          setErrors({ phone: errors._non_field_errors });
        } else {
          setErrors(errors);
        }
      } else {
        setErrors({ showCommonError: true });
      }
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      setSubmitting(false);
    } finally {
      if (!redirectParam) {
        dispatch(confirmSignUpFulfill());
      }
    }
  };

export const confirmUnmaskedSignUp = createAppAsyncThunk(
  'user/confirmUnmaskedSignUp',
  async (
    {
      id,
      email,
      password,
      phone,
      source,
      unmaskedSignupToken,
      onSuccess,
      onError,
    }: {
      id: string;
      email?: string;
      password: string;
      phone?: string;
      source: typeof SOURCE_EMAIL | typeof SOURCE_SMS;
      unmaskedSignupToken: string;
      onSuccess: () => void;
      onError: (error: any) => void;
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const { locale } = getState().intl;
      await signUp({ source, password, email, phone, id, token: unmaskedSignupToken, locale });
      onSuccess();
      return null;
    } catch (error) {
      onError(error);
      return rejectWithValue(error);
    }
  },
);

export const forgotPassword = createAppAsyncThunk(
  'user/forgotPassword',
  async ({ isPhone, value }: any, { rejectWithValue, getState }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ [isPhone ? 'phone' : 'email']: value });

      await api.post(`${getAPIHost()}/customers/forgot_password_initiate/?${searchParams}`, false, { body });
      return null;
    } catch (error) {
      return rejectWithValue(handleFormikAWSFormError(error));
    }
  },
);

export const forgotPasswordSubmit = createAppAsyncThunk(
  'user/forgotPasswordSubmit',
  async ({ id, password, passwordToken, setSubmitting }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const { locale } = getState().intl;
      const searchParams = new URLSearchParams({ language: locale });
      const body = JSON.stringify({ token: passwordToken, password });

      await api.post(`${getAPIHost()}/customers/${id}/forgot_password_confirm/?${searchParams}`, false, { body });
      await dispatch(login({ cognitoUserID: id, password, setSubmitting }));
      setSubmitting(false);
      return null;
    } catch (error) {
      setSubmitting(false);
      return rejectWithValue(handleFormikAWSFormError(error));
    }
  },
);
