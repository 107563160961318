export const localePrefix = '/:locale([a-zA-Z_-]{2,})';
// TODO: remove 'protection wallet' related pages when content is removed
export const authenticatedStaticPages =
  '/:slug(xcover-protection-wallet|xcover-protection-wallet-coming-soon|xcover-activate-esim)';
export const authRoutes = [
  {
    key: 'account',
    path: `${localePrefix}/account`,
    exact: true,
  },
  {
    key: 'profile',
    path: `${localePrefix}/profile`,
    exact: true,
  },
  {
    key: 'payoutSubmitted',
    path: `${localePrefix}/account/payout/:payoutID/submitted`,
    exact: true,
  },
  {
    key: 'payout',
    path: `${localePrefix}/account/payout/:payoutID`,
    exact: true,
  },
  {
    key: 'paypalInterstitial',
    path: `${localePrefix}/paypal-connect`,
    exact: true,
  },
  {
    key: 'modifyBooking',
    path: `${localePrefix}/modify/:id`,
    exact: true,
  },
  {
    key: 'summary',
    path: `${localePrefix}/summary`,
    exact: true,
  },
  {
    key: 'offers',
    path: `${localePrefix}/offers`,
    exact: true,
  },
  {
    key: 'authenticatedStaticPages',
    path: localePrefix + authenticatedStaticPages,
    exact: true,
  },
  {
    key: 'ebayPaymentDetails',
    path: `${localePrefix}/ebay-payment-details/:ins`,
    exact: true,
  },
];
