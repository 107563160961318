import { createGlobalStyle } from 'styled-components';
import globalCardStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalCardStyle';
import globalBadgeStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalBadgeStyle';
import globalFormStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalFormStyle';
import globalGridStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalGridStyle';
import globalDropdownStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalDropdownStyle';
import globalPopoverStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalPopoverStyle';
import globalTooltipStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalTooltipStyle';
import globalNavStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalNavStyle';
import globalTypeStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalTypeStyle';
import globalModalStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalModalStyle';
import globalTableStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalTableStyle';
import globalGenericStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalGenericStyle';
import globalLinkStyle from '@whitelabel/component-library/src/styled/GlobalStyle/globalLinkStyle';

const GlobalXCoverStyle = createGlobalStyle`
  ${globalCardStyle}
  ${globalBadgeStyle}
  ${globalFormStyle}
  ${globalGridStyle}
  ${globalDropdownStyle}
  ${globalCardStyle}
  ${globalPopoverStyle}
  ${globalTooltipStyle}
  ${globalNavStyle}
  ${globalTypeStyle}
  ${globalModalStyle}
  ${globalTableStyle}
  ${globalGenericStyle}
  a, .alert.alert a {  
    ${globalLinkStyle}  
  }
`;

export default GlobalXCoverStyle;
