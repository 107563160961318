export const XCOVER_PARTNER_ID = 'S6QVK';
export const SHOPEE_PARTNER_ID = '3AZ60';
export const TILE_PARTNER_ID = '1H6C5';
export const FUNRAISIN_PARTNER_ID = '3014D';
export const PETPAL_PARTNER_ID = '0QRA8';
export const SKYSCANNER_PARTNER_ID = 'YE4IA';
export const AMAZON_PARTNER_ID = 'OBWZH';
export const AWS_CYBER_PROTECTION_PARTNER_ID = 'W4HO6';
export const KW_PREMIUM_HOME_WARRANTY_PARTNER_ID = 'C8IXH';
export const SHIPRUSH_PARTNER_ID = 'N1428';
export const ETRAVELI_PARTNER_ID = 'VEYWS';
export const RMS_PARTNER_ID = 'T69U3';
export const REVOLUT_PARTNER_ID = 'GX9AF';
export const STAGING_REVOLUT_PARTNER_ID = 'UASL5';
export const PRICELINE_PARTNER_ID = '0WAGA';
export const RYANAIR_PARTNER_ID = '71RKO';
export const VUELING_PARTNER_ID = 'TQLES';
export const SAS_PARTNER_ID = 'PJOE3';
export const EBAY_PARTNER_ID = 'ZNJ0A';
export const OMIO_PARTNER_ID = 'GFGL0';
export const HOME_TO_GO_PARTNER_ID = 'Q7YSW';
export const MAKE_MY_TRIP_PARTNER_ID = 'T5ZAE';
export const TOUR_RADAR_PARTNER_ID = 'PKQ3G';
export const HOLISTO_PARTNER_ID = 'S37OE';
export const NORSE_ATLANTIC_AIRWAYS_PARTNER_ID = '09GX9';
export const AIRBALTIC_PARTNER_ID = 'O2PKF';

export const INCLUSIONS_AND_EXCLUSIONS_PAGES_MAP = {
  [VUELING_PARTNER_ID]: 'claim-test-vueling',
  [SAS_PARTNER_ID]: 'claim-test-sas',
};
