import React, { ReactEventHandler } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, NavLink } from 'react-router-dom';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { bwClickTracking } from '@whitelabel/helpers/analytics';
import componentMessages from '../messages';
import { INavItem } from '../../../helpers/types';
import { getNormalOrReactRouterLinkProp } from '../../../helpers/utils';
import { StyledNavItem, StyledNavLink, StyledNavIcon } from './styledNavItem';

interface INavItemProps {
  item: INavItem;
  onClick?: ReactEventHandler;
  className?: string;
  hideOnMobileAndTablet?: boolean;
}
const NavItem = ({
  item: { messageKey, name, path, activePaths, icon, analyticsID, tag = NavLink },
  onClick,
  className,
  hideOnMobileAndTablet,
}: INavItemProps): JSX.Element => {
  const { locale, formatMessage } = useIntl();
  const { pathname } = useLocation();

  const messages = {
    ...commonMessages,
    ...componentMessages,
  };

  const renderChildren = () => (
    <>
      {icon ? <StyledNavIcon as={icon} /> : ''}
      {messageKey ? formatMessage(messages[messageKey as keyof typeof messages]) : name}
    </>
  );

  const onClickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }

    bwClickTracking(e);
  };

  return (
    <StyledNavItem data-test-id="NavItem" className={className} $hideOnMobileAndTablet={hideOnMobileAndTablet}>
      <StyledNavLink
        tag={tag}
        active={
          activePaths && !!activePaths.find((activePath: string) => pathname.startsWith(`/${locale}${activePath}`))
        }
        data-test-id="NavItem-link"
        data-analytics={analyticsID || `navbar${messageKey || name}`}
        onClick={onClickLink}
        {...getNormalOrReactRouterLinkProp(tag, locale, path)}
      >
        {renderChildren()}
      </StyledNavLink>
    </StyledNavItem>
  );
};

export default NavItem;
