import { css } from 'styled-components';
import { focusLinkStyle, focusVisibleMixin } from '@whitelabel/helpers/style/utils';
import { color } from '@whitelabel/helpers/style/colors';

const globalLinkStyle = css`
  box-shadow: none;
  color: ${color('blue')};
  text-decoration: underline;
  text-underline-offset: 2px;
  transition: all 0.25s ease-in-out;

  &:hover {
    box-shadow: none;
    filter: brightness(50%);
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  ${focusVisibleMixin(focusLinkStyle)}
`;

export default globalLinkStyle;
