import { fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';
import { USER_CONTINENT, USER_COUNTRY } from '@whitelabel/helpers/constants';
import { IDOB, IPartner, IRCPartner } from '@whitelabel/helpers/types';
import { getSessionStorage } from '@whitelabel/helpers/storageUtils';
import { dateISOtoDDMMYYYY } from '@whitelabel/helpers/date';
import { processResponse } from './api';
import { EDGE_CG_SERVICES_URL } from './constants';

export async function storeUserGeoIpInfo(): Promise<void> {
  try {
    const response = await fetch(`${EDGE_CG_SERVICES_URL}/geolocation`);
    const { countryCode, continentCode } = await processResponse(response);
    if (countryCode) {
      sessionStorage.setItem(USER_COUNTRY, countryCode);
    }
    if (continentCode) {
      sessionStorage.setItem(USER_CONTINENT, continentCode);
    }
  } catch {
    // ignore geoip error
  }
}

/**
 * Remove the email to be empty string if that email is our system generated
 */
export const filterGeneratedEmail = (email: string | undefined) => (email?.includes('@xcover.com') ? '' : email);

export const formatBirthDateToAPI = (birthDate?: IDOB): string | null => {
  const { dobYear, dobMonth, dobDay } = birthDate || {};
  if (!dobYear || !dobMonth || !dobDay) {
    return null;
  }
  return `${dobYear}-${dobMonth}-${dobDay.padStart(2, '0')}`;
};

export const formatBirthDateToForm = (birthDate?: string): IDOB | '' => {
  if (!birthDate) {
    return '';
  }

  const [dobDay, dobMonth, dobYear] = dateISOtoDDMMYYYY(birthDate).split('/');
  return { dobDay, dobMonth, dobYear };
};

export const isXCoverNextJSLive = () => {
  try {
    return getSessionStorage('isXCoverNextJSLive') === 'y';
  } catch {
    return false;
  }
};

export const appendExtraSearchParams = (searchParams: URLSearchParams, extraSearchParameters: Record<string, any>) => {
  for (const [key, value] of Object.entries(extraSearchParameters)) {
    searchParams.append(key, value);
  }
};

export const isRedirectLinkAllowed = (redirectLink: string) => {
  const trimQuery = redirectLink.split('?')[0];
  if (!trimQuery.includes('http://') && !trimQuery.includes('https://')) return true;

  try {
    const url = new URL(redirectLink);
    const xcoverReg = /(^|\.)xcover\.com$/;
    return xcoverReg.test(url.hostname);
  } catch (e) {
    return false;
  }
};

export type GetPartnerInfoArg = Partial<Pick<IPartner, 'id' | 'slug'>> & {
  subsidiary?: Pick<IPartner, 'id' | 'slug'>;
} & Partial<Pick<IRCPartner, 'code' | 'name'>>;
export const getPartnerInfo = (partner?: GetPartnerInfoArg) => ({
  partnerID: partner?.id || partner?.code,
  partnerName: partner?.slug || partner?.name,
  subsidiaryID: partner?.subsidiary?.id || partner?.id,
  subsidiaryName: partner?.subsidiary?.slug || partner?.slug,
});

export const getCurrentUserDetails = async () => {
  try {
    const [cognitoUser, cognitoAttributes] = await Promise.all([getCurrentUser(), fetchUserAttributes()]);

    return { cognitoUser, cognitoAttributes };
  } catch (error: any) {
    if (error.name !== 'UserUnAuthenticatedException') throw error;
    return {};
  }
};
