import { xcoverAPI } from '@whitelabel/xcover-shared/store/services/xcover/index';
import { processKeys } from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { RTK_CACHE_LIFE } from '@whitelabel/xcover-shared/helpers/constants';
import { FeatureFlag } from '../../../../helpers/types';

export const featureFlagsAPI = xcoverAPI.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<FeatureFlag, void>({
      query: () => {
        const url = `${getAPIHost()}/features/`;
        return url;
      },
      transformResponse: (response: unknown): FeatureFlag => processKeys(response as object),
      keepUnusedDataFor: RTK_CACHE_LIFE,
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featureFlagsAPI;
