import { Amplify } from 'aws-amplify';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { ICustomerRegion } from '@whitelabel/helpers/types';
import { getLocalStorage } from '@whitelabel/helpers/storageUtils';
import { IError } from './types';
import {
  AP_SOUTH_1,
  EU_CENTRAL_1,
  CUSTOMER_REGION,
  US_EAST_1,
  SIGNOUT_CALLBACK_PATH,
  SIGNIN_CALLBACK_PATH,
} from './constants';

import { API_HOST } from './api';

// This is from:
// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export async function hash(text: string) {
  const msgUint8 = new TextEncoder().encode(text); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}

export async function fetchAndStoreCustomerRegion(emailOrPhone: string): Promise<ICustomerRegion> {
  try {
    const hashedEmailOrPhone = await hash(emailOrPhone.trim().toLocaleLowerCase());
    const response = await fetch(`${API_HOST}/v1/customers/region/${hashedEmailOrPhone}/`);
    const data = (await response.json()) as ICustomerRegion | IError;

    if (response.ok) {
      localStorage.setItem(CUSTOMER_REGION, (data as ICustomerRegion).region);
    } else {
      const apiError = data as IError;
      const requestId = response.headers.get('x-request-id');
      const error: IError = new Error(apiError.message);
      error.requestId = requestId;
      error.status = response.status;
      error.code = apiError.errors?.code;
      throw error;
    }
    return data as ICustomerRegion;
  } catch (e) {
    if ((e as IError).status !== 404) {
      captureExceptionWithFullStory(e as IError, {
        emailOrPhone,
        error: JSON.stringify(e),
        url: `/api/v1/customers/region/`,
      });
    }
    throw e;
  }
}

export function getStoredCustomerRegion() {
  return getLocalStorage(CUSTOMER_REGION);
}

export function configureCognito() {
  const region = getStoredCustomerRegion();
  if (region) {
    let cognitoRegion = process.env.REACT_APP_COGNITO_REGION || process.env.NEXT_PUBLIC_COGNITO_REGION;
    let cognitoUserPoolID = process.env.REACT_APP_COGNITO_USER_POOL_ID || process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID;
    let cognitoAppClientID =
      process.env.REACT_APP_COGNITO_APP_CLIENT_ID || process.env.NEXT_PUBLIC_COGNITO_APP_CLIENT_ID;
    const authDomainPrefix =
      process.env.REACT_APP_COGNITO_AUTH_DOMAIN_PREFIX || process.env.NEXT_PUBLIC_COGNITO_AUTH_DOMAIN_PREFIX;

    if (region === AP_SOUTH_1) {
      cognitoRegion =
        process.env.REACT_APP_COGNITO_AP_SOUTH_1_REGION || process.env.NEXT_PUBLIC_COGNITO_AP_SOUTH_1_REGION;
      cognitoUserPoolID =
        process.env.REACT_APP_COGNITO_AP_SOUTH_1_USER_POOL_ID ||
        process.env.NEXT_PUBLIC_COGNITO_AP_SOUTH_1_USER_POOL_ID;
      cognitoAppClientID =
        process.env.REACT_APP_COGNITO_AP_SOUTH_1_APP_CLIENT_ID ||
        process.env.NEXT_PUBLIC_COGNITO_AP_SOUTH_1_APP_CLIENT_ID;
    } else if (region === US_EAST_1) {
      cognitoRegion =
        process.env.REACT_APP_COGNITO_US_EAST_1_REGION || process.env.NEXT_PUBLIC_COGNITO_US_EAST_1_REGION;
      cognitoUserPoolID =
        process.env.REACT_APP_COGNITO_US_EAST_1_USER_POOL_ID || process.env.NEXT_PUBLIC_COGNITO_US_EAST_1_USER_POOL_ID;
      cognitoAppClientID =
        process.env.REACT_APP_COGNITO_US_EAST_1_APP_CLIENT_ID ||
        process.env.NEXT_PUBLIC_COGNITO_US_EAST_1_APP_CLIENT_ID;
    }

    const authDomain = `${authDomainPrefix}.${cognitoRegion}.amazoncognito.com`;

    if (cognitoUserPoolID && cognitoAppClientID) {
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolId: cognitoUserPoolID,
            userPoolClientId: cognitoAppClientID,
            passwordFormat: {
              minLength: 8,
              requireLowercase: false,
              requireNumbers: true,
              requireUppercase: false,
              requireSpecialCharacters: false,
            },
            loginWith: {
              oauth: {
                domain: authDomain,
                redirectSignIn: [`${window.location.origin}${SIGNIN_CALLBACK_PATH}`],
                redirectSignOut: [`${window.location.origin}${SIGNOUT_CALLBACK_PATH}`],
                responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
                scopes: ['profile', 'email', 'openid', 'aws.cognito.signin.user.admin'],
              },
            },
          },
        },
      });
    }
  }
}

function getSelectedAPIHost(euCentral1APIHost: string, usEast1APIHost: string, apSouth1APIHost: string, ins?: string) {
  if (ins) {
    if (ins.startsWith('I-')) {
      return apSouth1APIHost;
    }
    if (ins.startsWith('U-')) {
      return usEast1APIHost;
    }
    return euCentral1APIHost;
  }

  const region = getStoredCustomerRegion();
  if (region === AP_SOUTH_1) {
    return apSouth1APIHost;
  }
  if (region === US_EAST_1) {
    return usEast1APIHost;
  }
  return euCentral1APIHost;
}

export function getAPIHost(ins?: string, version: 'v1' | 'v2' = 'v1') {
  const addVersion = (url: string) => `${url}/${version}`;

  const apSouth1APIHost = addVersion(
    (process.env.REACT_APP_AP_SOUTH_1_API_HOST || process.env.NEXT_PUBLIC_AP_SOUTH_1_API_HOST)!,
  );
  const usEast1APIHost = addVersion(
    (process.env.REACT_APP_US_EAST_1_API_HOST || process.env.NEXT_PUBLIC_US_EAST_1_API_HOST)!,
  );
  const euCentral1APIHost = addVersion(API_HOST || '/api');

  return getSelectedAPIHost(euCentral1APIHost, usEast1APIHost, apSouth1APIHost, ins);
}

export function getNestAPIHost(ins?: string, version: 'v1' | 'v2' = 'v1') {
  const addVersion = (url: string) => `${url}/${version}`;

  const apSouth1APIHost = addVersion('/router/api-xc-ap_south_1');
  const usEast1APIHost = addVersion('/router/api-xc-us_east_1');
  const euCentral1APIHost = addVersion('/router/api-xc-eu_central_1');

  return getSelectedAPIHost(euCentral1APIHost, usEast1APIHost, apSouth1APIHost, ins);
}

/**
 * Get the API Host for claims portal (claims-portal-backend API => XC & RC)
 */
export function getCPNestAPIHost(ins?: string) {
  const apSouth1APIHost = (process.env.VITE_AP_SOUTH_1_API_HOST || process.env.NEXT_PUBLIC_CP_AP_SOUTH_1_API_HOST)!;

  const usEast1APIHost = (process.env.VITE_US_EAST_1_API_HOST || process.env.NEXT_PUBLIC_CP_US_EAST_1_API_HOST)!;

  const euCentral1APIHost = (process.env.VITE_API_HOST || process.env.NEXT_PUBLIC_CP_API_HOST)!;

  return getSelectedAPIHost(euCentral1APIHost, usEast1APIHost, apSouth1APIHost, ins);
}

export function storeCustomerRegion(region: string | null) {
  /**
   * If region is available in the URL, force update the region.
   * Else check if customerRegion already exist in LS.
   ** If there's an existing LS, honor the existing customerRegion and no changes.
   ** Else default the customerRegion to EU_CENTRAL_1.
   */
  localStorage.setItem(CUSTOMER_REGION, region || localStorage.getItem(CUSTOMER_REGION) || EU_CENTRAL_1);
}

export function storeCustomerRegionFromURL(search?: string) {
  const urlParams = new URLSearchParams(search || window.location.search);
  const region = urlParams.get('region');
  storeCustomerRegion(region);
}
