// Retry function to help reduce the frequency of chunk load issues occurring and the instances recorded in Sentry.
import React, { useCallback, useState, ComponentType, createContext } from 'react';
import { get, kebabCase, isEmpty } from 'lodash/fp';
import { Link, Redirect, NavLink } from 'react-router-dom';
import { IntlShape } from 'react-intl';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import * as FullStory from '@fullstory/browser';
import { decamelizeKeys } from 'humps';
import { getSessionStorage } from '@whitelabel/helpers/storageUtils';
import {
  ERROR_CODE,
  HELP_LOGIN_MODAL_DISPLAYED,
  OBJECT_STRING,
  COOKIE_MARKETING,
  COOKIE_YES_COOKIE_NAME,
  CES_SELECTED_LIST,
} from '@whitelabel/xcover-shared/helpers/constants';
import { IError, IDictionary } from '@whitelabel/xcover-shared/helpers/types';
import commonMessages from '@whitelabel/helpers/messages/commonMsg';
import { configureCognito, fetchAndStoreCustomerRegion } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { isOnServer } from '@whitelabel/helpers/utils';
import { POLICY_TYPE } from '@whitelabel/helpers/constants';
import { IShouldLogInArgs } from './types';
import loginMsg from './messages/loginMsg';

export const retry = (
  fn: (...args: any[]) => any,
  retriesLeft = 5,
  interval = 1000,
): Promise<{ default: ComponentType<React.PropsWithChildren<any>> }> =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

export const getCookie = (cookieName: string) =>
  isOnServer ? undefined : document.cookie.split(';').find((item) => item.includes(cookieName));

export const contentBlocksToNavItems = (blocks: any, pathPrefix = '') => {
  let currentRootItem = '';

  const navItems = blocks
    .map(({ value }: any) => value)
    .flat()
    .filter(({ type, value }: any) => type === 'heading' && (value.headingTag === 'h2' || value.headingTag === 'h3'))
    .reduce((items: any, { value: { title, headingTag } }: any) => {
      const key = kebabCase(title);
      const path = pathPrefix ? `${pathPrefix}-${key}` : key;
      const item = { key, name: title, path };

      if (headingTag === 'h2') {
        currentRootItem = key;
      }

      if (!currentRootItem || key === currentRootItem) {
        return { ...items, [key]: item };
      }

      const siblingItems = get(`${currentRootItem}.items`, items) || [];
      const rootItem = { ...items[currentRootItem], items: [...siblingItems, item] };

      return {
        ...items,
        [currentRootItem]: rootItem,
      };
    }, {});

  return Object.values(navItems);
};

export const PartnerIDContext = createContext({ partnerID: undefined });

export const renderDisclaimerContent = (content: any) =>
  content?.includes('</p>') ? ReactHtmlParser(content) : <p>{content}</p>;

export const shouldLogIn = ({
  user,
  securityToken,
  locale,
  location,
  hasQueryParams,
  dispatch,
}: IShouldLogInArgs): JSX.Element | undefined => {
  if (!user && !securityToken) {
    let search = '';
    if (hasQueryParams) {
      const params = new URLSearchParams(location.search);
      search = params.toString() || '';
    }
    return <Redirect to={{ pathname: `/${locale}/login`, search, state: { referrer: location } }} />;
  }
  if (user && securityToken) {
    // using type cus of circular deps, temp till fnol migration is done
    dispatch({ type: 'user/LOGOUT/TRIGGER', payload: `/${locale}` });
    return undefined;
  }
  return undefined;
};

/**
 * Remove the email to be empty string if that email is our system generated
 */
export const filterGeneratedEmail = (email: string | undefined) => (email?.includes('@xcover.com') ? '' : email);

export const useOpenLoginModal = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const openLoginModal = useCallback(() => {
    const loginModalDisplayed = sessionStorage.getItem(HELP_LOGIN_MODAL_DISPLAYED);
    if (!loginModalDisplayed) {
      setIsLoginModalOpen(true);
    }
  }, []);

  return [isLoginModalOpen, setIsLoginModalOpen, openLoginModal] as const;
};

export const getNormalOrReactRouterLinkProp = (tag: React.ElementType, locale: string, path: string) =>
  tag === Link || tag === NavLink
    ? {
        to: `/${locale}${path}`,
      }
    : {
        href: `/${locale}${path}`,
      };

export const getCustomerErrorMsg = (intl: IntlShape, error: Record<string, any>, customErrorMsg = '') => {
  let errMessage = '';
  if (error?.code) {
    switch (error.code) {
      case ERROR_CODE.EMAIL_NOT_FOUND:
        errMessage = intl.formatMessage(loginMsg.invalidEmailAddressMessage);
        break;
      case ERROR_CODE.PHONE_NOT_FOUND:
        errMessage = intl.formatMessage(loginMsg.invalidMobileNumberMessage);
        break;
      case ERROR_CODE.ALREADY_REGISTERED:
        errMessage = intl.formatMessage(loginMsg.alreadyRegisteredMessage);
        break;
      // no default
    }
  } else if (error && !isEmpty(error)) {
    const firstErrorMsg = Object.values(error).find((value) => Array.isArray(value));
    if (firstErrorMsg) errMessage = firstErrorMsg[0] as string;
  }
  const errMsg = errMessage || customErrorMsg || (error?.message as string);

  if (errMsg && (typeof errMsg === 'object' || errMsg === OBJECT_STRING)) {
    return intl.formatMessage(commonMessages.error);
  }

  return errMsg;
};

export const getCustomerRegion = async (
  emailOrPhone: string,
  isPhone: boolean,
  intl: IntlShape,
  setError: (arg0: string) => void,
  setSubmitting: (arg0: boolean) => void,
  isFallback?: boolean,
) => {
  try {
    const data = await fetchAndStoreCustomerRegion(emailOrPhone);
    configureCognito();
    return data;
  } catch (e) {
    if ((e as IError).status === 404) {
      if (isFallback) throw e;
      let errMsg = intl.formatMessage(loginMsg.invalidEmailAddressMessage);
      if (isPhone) {
        errMsg = intl.formatMessage(loginMsg.invalidMobileNumberMessage);
      }
      setError(errMsg);
    } else {
      setError(intl.formatMessage(commonMessages.error));
    }
    setSubmitting(false);
    throw e;
  }
};

export const initializeFullStory = () =>
  FullStory.init({ orgId: '34DW0', devMode: process.env.NODE_ENV === 'development' });

export const getCookieObject = (name: string) => {
  const cookieVal = getCookie(name)?.split('=')[1].split(',') || [];
  const cookieObject = cookieVal?.reduce((accumulator, currentValue) => {
    const [key, val] = currentValue.split(':');
    accumulator[key] = val;
    return accumulator;
  }, {} as IDictionary<String>);
  return cookieObject;
};

export const hasCookieYesActioned = () => getCookieObject(COOKIE_YES_COOKIE_NAME).action === 'yes';
export const hasCookieYesConsented = () => getCookieObject(COOKIE_YES_COOKIE_NAME).consent === 'yes';
export const hasCookieYesMarketingAccepted = () =>
  hasCookieYesConsented() && getCookieObject(COOKIE_YES_COOKIE_NAME)[COOKIE_MARKETING] === 'yes';

export const handleQBQuoteBody = ({
  quoteId,
  plan,
  updatedAddOns,
}: {
  quoteId: string;
  plan?: string;
  updatedAddOns?: Record<string, number>;
}) => {
  const body = (() => {
    const obj: { policy_type: string; quote_id: string; plan?: string; add_ons?: Record<string, unknown> } = {
      policy_type: POLICY_TYPE.KW_HOME_WARRANTY,
      quote_id: quoteId,
    };
    if (plan) {
      obj.plan = plan;
    }
    if (updatedAddOns) {
      obj.add_ons = decamelizeKeys(updatedAddOns) as Record<string, number>;
    }
    return obj;
  })();
  return body;
};

export const shouldShowCESSurvey = (slug: string) => {
  const submittedCESPaths = getSessionStorage<{ [slug: string]: string }>(CES_SELECTED_LIST, true);
  if (!submittedCESPaths?.[slug]) return true;
  const oneDayAfterSubmission = moment(submittedCESPaths[slug]).add(1, 'day').format();
  const currentTime = moment().format();
  return moment(currentTime).isSameOrAfter(oneDayAfterSubmission);
};
